import { formatISO } from 'date-fns'
import { useMutation, useQuery } from 'react-query'
import { handleTypes } from '../components/Error'
import Message from '../components/Message'
import fetchJson from '../utils/fetchJson'
import { useEaseeItems } from './Item'
import { useAuth } from './Auth'
import { useStrings } from './useStrings'
import { sessionFetch } from './useUsersEnergy'

const key = 'chargerSession'

export const useLatestChargingSession = id => {
  const { user } = useAuth()

  return useQuery(
    [key, id],
    async () =>
      await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/easeehome/getlatestchargingsession/${id}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/*+json',
            'X-Authorization': user?.TokenInfo?.Token,
          },
        },
      ),
    {
      enabled: !!id && !!user,
    },
  )
}

export const useChargerSessions = () => {
  const { user } = useAuth()
  const { data: items } = useEaseeItems()
  const { lng } = useStrings('components', 'hookMessages')

  return useMutation(
    async values => {
      const from = formatISO(new Date(values?.date[0]))
      const to = formatISO(new Date(values?.date[1]))
      const chargerIds = Object.hasOwnProperty.call(values, 'chargerId')
        ? [values?.chargerId]
        : items?.map(item => item?.id)

      const chunkSize = 15
      const queries = []
      for (let i = 0; i < chargerIds.length; i += chunkSize) {
        const chunk = chargerIds.slice(i, i + chunkSize)
        queries.push(chunk)
      }

      const tasks = []

      for (const query of queries) {
        const res = sessionFetch(from, to, query, user?.TokenInfo?.Token)
        tasks.push(res)
      }
      const res = await Promise.all(tasks)

      let result = []
      for (const re of res) {
        result = [...result, ...re]
      }

      return result
    },
    {
      onError: error => {
        Message(`${handleTypes(error)}`, 'error')
      },
      onSuccess: async () => {
        Message(`${lng('getSuccess')}`, 'success')
      },
    },
  )
}
