import { Form, Button, Alert } from 'antd'
import moment from 'moment'
import CPOForm from '../../components/Form'
import { required } from '../../components/Form/validations'
import DateInput from '../../components/Form/Inputs/DateInput'
import CPOTable from '../../components/Table'
import { useStrings } from '../../hooks/useStrings'
import { createTableHeaders } from '../../utils/createTableHeaders'
// import { SettingsContext } from '../../contexts/Settings'
import {
  useEffect,
  // useContext,
  useState,
} from 'react'
import { useChargerSessions } from '../../hooks/ChargerSessions'
import { useItemsWithShares } from '../../hooks/Item'
import { useSubUsers } from '../../hooks/SubUser'
import ExpandedRowRender from './KWhPerUser/ExpandedRowRender'
import { Link } from 'react-router-dom'

const KWhPerCharger = () => {
  const { lng } = useStrings('pages', 'KWhPerCharger')
  // const { settings } = useContext(SettingsContext)
  const [formattedSessions, setFormattedSessions] = useState(null)
  const [submittedValues, setSubmittedValues] = useState(null)
  const [errorChargers, setErrorChargers] = useState([])

  const {
    data: sessions,
    mutateAsync: getSessions,
    isLoading,
    isError,
  } = useChargerSessions()
  const {
    data: shares,
    isLoading: isSharesLoading,
    isError: isSharesError,
  } = useItemsWithShares()
  const {
    data: subUsers,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useSubUsers()

  const headers = createTableHeaders(lng, ['username', 'name', 'kwh'])

  const childHeaders = createTableHeaders(
    lng,
    [
      'name',
      'chargerId',
      'kwh',
      // 'price'
    ],
    // settings,
  )

  useEffect(() => {
    if (
      sessions &&
      !isLoading &&
      !isError &&
      subUsers &&
      !isUserLoading &&
      !isUserError &&
      shares &&
      !isSharesLoading &&
      !isSharesError
    ) {
      setErrorChargers([])

      // Check for Chargers with multiple Users.
      for (const share of shares) {
        if (share?.sharedWith?.length > 1) {
          let unique = false

          for (let sh of share.sharedWith) {
            for (let sh2 of share.sharedWith) {
              if (sh?.SharedToUserId !== sh2?.SharedToUserId) {
                unique = true
                break
              }
            }
            if (unique) {
              break
            }
          }

          if (unique) {
            setErrorChargers(prev => [
              { id: share?.id, name: share?.name },
              ...prev,
            ])
          }
        }
      }

      const formattedData = subUsers?.map((user, i) => {
        let totalKwh = 0
        const chargersSharedWithUser = shares?.filter(share => {
          return share?.sharedWith?.some(
            shareUser => shareUser?.SharedToUserId === user?.User?.UserId,
          )
        })

        const subTable = sessions
          ?.filter(session =>
            chargersSharedWithUser?.some(
              share => share?.id === session?.ChargerId,
            ),
          )
          ?.map((session, j) => {
            const kwh = session?.ChargerSessions?.reduce(
              (acc, cSession) => acc + cSession?.kiloWattHours,
              0,
            )

            totalKwh += kwh

            return {
              key: `child-${j}`,
              name: shares?.find(share => share?.id === session?.ChargerId)
                ?.name,
              chargerId: session?.ChargerId,
              kwh,
            }
          })

        return {
          key: i,
          username: user?.User?.Username,
          name: `${user?.UserInfo?.FirstName} ${user?.UserInfo?.LastName}`,
          kwh: totalKwh,
          subTable: subTable,
        }
      })

      setFormattedSessions(formattedData)
    }
  }, [
    sessions,
    isLoading,
    isError,
    shares,
    isSharesLoading,
    isSharesError,
    subUsers,
    isUserLoading,
    isUserError,
  ])

  const onSubmit = async values => {
    await getSessions(values)
    setSubmittedValues(values)
  }

  return (
    <div className="padding">
      <CPOForm onSubmit={onSubmit}>
        <div style={{ width: '400px' }}>
          <DateInput
            name="date"
            label={lng('timeRangeLabel')}
            validate={[required]}
            inputProps={{
              style: { width: '100%' },
              showTime: {
                defaultValue: [
                  moment('00:00:00', 'HH:mm:ss'),
                  moment('00:00:00', 'HH:mm:ss'),
                ],
                format: 'HH',
              },
            }}
          />
        </div>

        <Form.Item>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {lng('generateBtn')}
          </Button>
        </Form.Item>
      </CPOForm>

      <div>
        {errorChargers?.length > 0 && (
          <Alert
            message={
              <span>
                {lng('charger')}{' '}
                {errorChargers?.map((charger, i) => (
                  <span key={i}>
                    {i > 0 ? ', ' : null}
                    <Link to={`/charger/${charger?.id}`}>{charger?.name}</Link>
                  </span>
                ))}{' '}
                {lng('isShared')}
              </span>
            }
            description={lng('alertDescription')}
            type="error"
          />
        )}
      </div>

      <div>
        <CPOTable
          summary={{
            keys: [
              'kwh',
              'from',
              'to',
              // 'price'
            ],
            date: {
              from: submittedValues?.date?.[0].format('YYYY-MM-DD HH:mm:ss'),
              to: submittedValues?.date?.[1].format('YYYY-MM-DD HH:mm:ss'),
            },
          }}
          subTable={{ headers: childHeaders }}
          data={formattedSessions}
          headers={headers}
          expandable={{
            expandedRowRender: (record, index) => (
              <ExpandedRowRender
                record={record}
                index={index}
                headers={childHeaders}
              />
            ),
          }}
        />
      </div>
    </div>
  )
}

export default KWhPerCharger
