import { formatISO } from 'date-fns'
import { useQueries } from 'react-query'
import fetchJson from '../utils/fetchJson'
import { eventKey, fetchEvents } from './Event'
import { useEaseeItems } from './Item'
import { fetchSubUsers, subUserKey } from './SubUser'
import { useAuth } from './Auth'

export const sessionFetch = async (from, to, chargerIds, token) => {
  return await fetchJson(
    `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/easeehome/getchargersessions`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/*+json',
        'X-Authorization': token,
      },
      body: JSON.stringify({
        ChargerId: chargerIds,
        From: from,
        To: to,
      }),
    },
  )
}

export const useUsersEnergy = values => {
  const { user } = useAuth()
  const { data: items } = useEaseeItems()

  const from = formatISO(new Date(values?.date[0]))
  const to = formatISO(new Date(values?.date[1]))
  const chargerIds = items?.map(item => item?.id)

  const chunkSize = 15
  const queries = []
  for (let i = 0; i < chargerIds.length; i += chunkSize) {
    const chunk = chargerIds.slice(i, i + chunkSize)
    queries.push(chunk)
  }

  return useQueries([
    {
      queryKey: ['sessions'],
      queryFn: async () => {
        const tasks = []

        for (const query of queries) {
          const res = sessionFetch(from, to, query, user?.TokenInfo?.Token)
          tasks.push(res)
        }
        const res = await Promise.all(tasks)

        let result = []
        for (const re of res) {
          result = [...result, ...re]
        }

        return result
      },
      enabled: !!to && !!from && !!user && chargerIds?.length > 0,
    },
    {
      queryKey: [eventKey],
      queryFn: fetchEvents(from, to, user),
      enabled: !!to && !!from && !!user,
    },
    {
      queryKey: [subUserKey],
      queryFn: fetchSubUsers(user),
      enabled: !!user,
    },
  ])
}
